<template>
  <div class="banner_main">
    <div class="banner_main__background">
      <img
        :src="banner?.images?.banner_1348_359"
        :alt="banner?.vodInfo?.title"
      />
    </div>

    <Icon
      class="poster_info__vendor"
      :icon="`img/vodInfo_${banner?.show_condition?.params[0]}`"
      :is-local="false"
      :alt="banner?.show_condition?.params[0]"
    />

    <div class="banner_main__pins poster_info__pins_absolute">
      <Targets :content-item="banner?.vodInfo" />
    </div>
  </div>
</template>

<script setup>
import Icon from '@/components/common/icons/icon.vue';
import Targets from '@/components/ui/Targets.vue';

const emit = defineEmits(['change-big-banner']);

const props = defineProps({
  banner: {
    type: Object,
    default: () => ({}),
  },
});

watch(
  () => props.banner,
  (newValue) => {
    console.log('change')
    emit('change-big-banner')
  },
  { deep: true }
);
</script>
