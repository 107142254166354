<template>
  <Swiper
    v-if="bannerList.length"
    :navigation="true"
    :modules="[ Navigation, Thumbs ]"
    :slides-per-view="getSlidesParams?.perView"
    :space-between="getSlidesParams?.spaceBetween"
    class="banner_main__buttons_block banner_main__sliders_block"
    :observer="true"
    :style="`display: ${count > 1 ? 'block' : 'none'}; width:${allWidth}px`"
    @swiper="setThumbsSwiper"
    :thumbs="{ swiper: thumbsSwiperSlide }"
    :watchSlidesProgress="true"
    :initialSlide="selected"
  >
    <SwiperSlide
      v-for="(content, index) in bannerList"
      :key="String(content?.vodInfo?.id + index)"
    >
      <BannerCard :content="content?.vodInfo" />
    </SwiperSlide>
  </Swiper>
</template>

<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Thumbs } from 'swiper';
import BannerCard from '@/components/ui/Banners/BannerCard.vue';

const props = defineProps({
  bannerList: {
    type: Array,
    default: () => [],
  },
  currentBanner: {
    type: Object,
    default: () => ({}),
  },
  sizes: {
    type: Object,
    default: {},
  },
  selected: {
    type: Number,
    default: 0
  }
});
const emit = defineEmits(['init-swiper-banner']);

const slideKey = useLocalStorage('sliderKey', { bannerList: 0 });
const allWidth = ref(props.sizes.parentComponentWidth);

const thumbsSwiperSlide = ref(null)

const setThumbsSwiper = (swiper) => {
  emit('init-swiper-banner', swiper)
};

// расчёт количество мини баннеров для свайпера
const count = ref(0);
const getSlidesParams = computed(() => {
  const width = props.sizes.parentComponentWidth * 0.95;
  const spaceBetween = width < 720 ? 16 :
    width < 1024 ? 18 :
    width < 1280 ? 20 :
    width < 1920 ? 24 : 26;

  const widthSwiper = props.sizes.parentComponentWidth - props.sizes.componentWidth;
  count.value = Math.floor(widthSwiper / (props.sizes.componentHeight * 0.65 + spaceBetween));
  if (count.value > props.bannerList.length) count.value = props.bannerList.length;

  allWidth.value = count.value * (props.sizes.componentHeight * 0.65 + spaceBetween) - spaceBetween;

  return { perView: count.value, spaceBetween};
});
</script>
