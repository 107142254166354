<template>
  <div
    id="banner_main__buttons_block"
    class="banner_main__buttons_block flex"
    ref="buttonsContainer"
  >
    <a
      :href="generateUrl(banner[currentSelect])"
      @click.prevent="onClickBanner(banner[currentSelect])"
      class="poster_info__button"
      :class="{ disabled: !isVod }"
    >
      <div class="poster_info__button_title">Смотреть</div>
    </a>

    <button
      class="poster_info__button"
      :class="{ selected: showDetail }"
      @click="toggleDetail"
    >
      Детали
    </button>
<!--    <Icon-->
<!--      v-if="showDetail"-->
<!--      class="poster_info__detail_selected"-->
<!--      icon="ui/poster_selected"-->
<!--    />-->

    <FavButton
      :content="contentItem"
      :type="isVod ? 'film' : 'channel'"
    />
  </div>
</template>

<script setup>
import Icon from '@/components/common/icons/icon.vue';
import FavButton from '@/components/ui/FavButton.vue';

const props = defineProps({
  banner: {
    type: Object,
    default: () => ({}),
  },
  currentSelect: {
    type: Number,
    default: 0
  },
  onData: Function
});
const emit = defineEmits(['set-content-item', 'toggle-detail']);

const contentItem = shallowRef({});
const isVod = shallowRef(false);

const showDetail = shallowRef(false);
function toggleDetail() {
  showDetail.value = !showDetail.value;
  emit('toggle-detail', showDetail.value);
}

function updateContentItem() {
  contentItem.value = null;

  if (!props.banner || !props.banner.length) return;

  const bannerItem = props.banner[props.currentSelect] || {};
  isVod.value = bannerItem.click_action?.algorithm === 'throw_to_video';
  if (isVod.value) {
    contentItem.value = {
      ...bannerItem.vodInfo,
      contentId: bannerItem.vodInfo?.id,
      contentType: 'vod',
    };
  } else {
    // TV
  }
  emit('set-content-item', contentItem.value);
}

watch(
    () => props.banner,
    updateContentItem,
    { immediate: true },
);

watch(
    () => props.currentSelect,
    updateContentItem,
    { immediate: true },
);


function generateUrl(banner) {
  if (isVod.value) return `/vod/${banner.click_action.params[0]}`;
  // if (!isVod.value)
}
function onClickBanner(banner) {
  if (isVod.value) navigateTo({ name: 'vod-name', params: { name: banner.click_action.params[0] } });
  // if (!isVod.value)
}

const componentWidth = ref(null);
const componentHeight = ref(null);
const parentComponentWidth = ref(null);
const sendDataToParent = (componentWidth, parentComponentWidth, componentHeight) => {
  if (typeof props.onData === 'function') {
    props.onData({ componentWidth, parentComponentWidth, componentHeight });
  }
};

let resizeTimer;
onMounted(() => {
  const element = document.getElementById('banner_main__buttons_block');
  if (element) {
    componentWidth.value = element.getBoundingClientRect().width;
    componentHeight.value = element.getBoundingClientRect().height;
    parentComponentWidth.value = element.parentElement.offsetWidth;
    sendDataToParent(componentWidth.value, parentComponentWidth.value, componentHeight.value);

    window.addEventListener('resize', () => {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        componentWidth.value = element.getBoundingClientRect().width;
        componentHeight.value = element.getBoundingClientRect().height;
        parentComponentWidth.value = element.parentElement.offsetWidth;
        sendDataToParent(componentWidth.value, parentComponentWidth.value, componentHeight.value);
      }, 300);
    });
  }
});

onUnmounted(() => {
  if(process.server) return null;
  if (resizeTimer) {
    clearTimeout(resizeTimer);
    resizeTimer = null;
  }
});
</script>
