<template>
  <div class="banner_card__poster pointer">
    <img
      :src="imageUrl"
      :alt="content?.serial_title || content?.title"
      @error="handleImageError"
    />
  </div>
</template>

<script setup>
const props = defineProps({
  content: {
    type: Object,
    default: () => ({}),
  },
  defaultImage: {
    type: String,
    default: '/img/default/poster_218_324/default.jpg'
  }
});

const imageUrl = ref(props.content?.images?.poster_218_324);

const handleImageError = () => {
  imageUrl.value = props.defaultImage;
}

const checkImageAvailability = () => {
  const img = new Image();
  img.onload = () => {};
  img.onerror = () => {
    imageUrl.value = props.defaultImage;
  };
  img.src = props.content?.images?.poster_218_324;
}

onMounted(() => {
  checkImageAvailability();
});
</script>
